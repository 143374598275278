import { useQuery } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import DeviceDetails, { IDeviceDetails } from "./../types/DeviceDetails";
import React from "react";
import { Outlet } from "react-router";
import { Q_DEVICE_FROM_USER } from "./../data/queries/device";
import { useSearchParams } from "react-router-dom";
import DeviceUtility from "./utilities/DeviceUtility";

const Main = () => {
  const { instance } = useMsal();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const { loading, error, data } = useQuery(Q_DEVICE_FROM_USER, {
    variables: { email: activeAccount?.idTokenClaims?.email?.toLowerCase() },
    skip: !activeAccount?.idTokenClaims?.email,
  });
  const [device, setDevice] = React.useState<IDeviceDetails>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const getUnmappedDeviceId = () => {
    return searchParams.get("deviceId")
    ? searchParams.get("deviceId")
    : DeviceUtility.getStoredUnmappedDeviceId();
  }

  React.useEffect(() => {
    const deviceIdParam = getUnmappedDeviceId()
      
    if (
      !activeAccount &&
      deviceIdParam &&
      DeviceUtility.validDeviceId(deviceIdParam)
    ) {
      const d = DeviceUtility.createEmptyDevice();
      d.deviceId = deviceIdParam;
      setDevice(d);
      DeviceUtility.storeUnmappedDeviceId(deviceIdParam);
    }

    if (data) {
      const d = DeviceUtility.createEmptyDevice();
      d.deviceId = data?.patient?.devices[0]?.deviceId;
      d.email = activeAccount?.idTokenClaims?.email?.toLowerCase();
      d.firstName = activeAccount?.idTokenClaims?.given_name;
      d.lastName = activeAccount?.idTokenClaims?.family_name;
      d.mappedToPatient = true;
      setDevice(d);
    }
  }, [data, searchParams]);
  return (
    <DeviceDetails.Provider value={device}>
      <Outlet />
    </DeviceDetails.Provider>
  );
};

export default Main;
