import { Route, Routes } from "react-router-dom";
import HomeComponent from "../HomeComponent";
import LearnComponent from "../LearnComponent";
import Main from "../Main";
import MessageComponent from "../MessageComponent";
import MyInfoComponent from "../MyInfoComponent";
import PatientSymptoms from "../PatientSymptoms";
import PatientSymptomsHistoryComponent from "../PatientSymptomsHistoryComponent";
import ClearDeviceIdComponent from "../ClearDeviceIdComponent";
import { APP_ROUTES } from "../../constants/appRoutes";
import OfflineComponent from "../OfflineComponent";
const RouteComponent = () => {
  return (
    <Routes>
      <Route path={APP_ROUTES.ROOT} element={<Main />}>
        <Route index element={<HomeComponent />} />
        <Route path={APP_ROUTES.HISTORY} element={<PatientSymptomsHistoryComponent />} />
        <Route path={APP_ROUTES.MY_INFO} element={<MyInfoComponent />} />
        <Route path={APP_ROUTES.LEARN} element={<LearnComponent />} />
        <Route path={APP_ROUTES.PATIENT_SYMPTOMS} element={<PatientSymptoms />} />
        <Route path={APP_ROUTES.OFFLINE} element={<OfflineComponent />} />
        <Route path={APP_ROUTES.MESSAGE} element={<MessageComponent />} />
        <Route path={APP_ROUTES.CLEAR_STORED_DEVICE_ID} element={<ClearDeviceIdComponent />} />
        <Route path="*" element={<>Nothing here</>} />
      </Route>
    </Routes>
  );
};

export default RouteComponent;
