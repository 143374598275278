import React from "react";

export interface IDeviceDetails {
  deviceId: string;
  mappedToPatient: boolean;
  firstName: string;
  lastName: string;
  email: string;
}

const defaultDevice: IDeviceDetails = {
  deviceId: "",
  mappedToPatient: false,
  firstName: "",
  lastName: "",
  email: "",
};

const DeviceDetails = React.createContext<IDeviceDetails>(defaultDevice);

export default DeviceDetails;
