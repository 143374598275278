import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
    Usage example:
  
      <BaxiAccordion 
          contextIcon={<PlumbingIcon />}
          contextTitle="Your title goes here"
          showShadowOutline
      >
          <YourComponent />
          Lorem ipsurm
      </BaxiAccordion>

 */
const BaxiAccordion = ({contextIcon, contextTitle, children, showShadowOutline = false}) => {

    return (
        <Accordion style={{boxShadow: showShadowOutline ? null : 'none'}}>
            <AccordionSummary 
                style={{padding: 0}}
                expandIcon={<ExpandMoreIcon />}
            >   
                <Grid
                    container
                    alignItems="center"
                >
                    {contextIcon}
                     <hr style={{width: "0px", margin: "0 4px", border: "none"}}/>
                    {contextTitle}
                </Grid>

            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default BaxiAccordion;