export const enum APP_ROUTES {
  "ROOT" = "/",
  "HISTORY" = "history",
  "MY_INFO" = "myinfo",
  "LEARN" = "learn",
  "PATIENT_SYMPTOMS" = "patientsymptoms",
  "OFFLINE" = "offline",
  "MESSAGE" = "message",
  "CLEAR_STORED_DEVICE_ID" = "clearStoredDeviceId",
}
