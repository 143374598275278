import { STORAGE_KEYS } from "../../constants/storageKeys";
import { IDeviceDetails } from "../../types/DeviceDetails";

export default class DeviceUtility {
  /**
   * Performs an initial sanity check for the device ID structure.
   * The device ID should have 2 blocks of 5 letters or digits (case insensitive) separated by a dash.
   * The first block should contain only letters or digits, while the second block should contain only letters, digits, and the letter "T" (if present).
   * This method checks if the given device ID meets these criteria.
   * @param {string} deviceId - The device ID to check.
   * @returns {boolean} Whether the device ID is valid or not.
   */
  static validDeviceId(deviceId: string) {
    return /^[A-HJ-KMNPR-Z0-9]{5}-[A-HJ-KMNPR-Z0-9]{4}[89ABCDEF](?:-T)?$/.test(
      deviceId
    );
  }

  static createEmptyDevice(): IDeviceDetails {
    return {
      deviceId: "",
      mappedToPatient: false,
      firstName: "",
      lastName: "",
      email: "",
    };
  }

  static storeUnmappedDeviceId(deviceId: string) {
    sessionStorage.setItem(STORAGE_KEYS.UNMAPPED_DEVICE_ID, deviceId);
  }

  static getStoredUnmappedDeviceId() {
    return sessionStorage.getItem(STORAGE_KEYS.UNMAPPED_DEVICE_ID);
  }
}
