import { useNavigate } from "react-router";
import { useEffect } from "react";
import { APP_ROUTES } from "../constants/appRoutes";
import { STORAGE_KEYS } from "../constants/storageKeys";

const ClearDeviceIdComponent = () => {
    const navigate = useNavigate()
    sessionStorage.removeItem(STORAGE_KEYS.UNMAPPED_DEVICE_ID)
    useEffect(() => {
        navigate(APP_ROUTES.ROOT)
    })
    return (<></>)
};

export default ClearDeviceIdComponent;
